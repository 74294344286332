import {doRequest} from '../../helpers/network';
import {Events} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';
import {Records} from '../../helpers/Urls';
import {
  EDIT_TRIP_ROUTE,
  EDIT_TRIP_ROUTE_COMPLETED,
  EDIT_TRIP_ROUTE_FAILED,
  MARK_TRIP_AS_BEGIN,
  MARK_TRIP_AS_BEGIN_COMPLETED,
  MARK_TRIP_AS_BEGIN_FAILED,
  ADDING_TRIP_COMMENT,
  ADDING_TRIP_COMMENT_FAILED,
  ADDING_TRIP_COMMENT_COMPLETED,
  ADD_RUNNING_STATUS,
  ADD_RUNNING_STATUS_COMPLETED,
  ADD_RUNNING_STATUS_FAILED,
  TRIP_DETAILS_MESSAGE,
  TRIP_DETAILS_MESSAGE_COMPLETED,
  TRIP_DETAILS_MESSAGE_FAILED,
  FETCH_TRACKING_LOCATION,
  FETCH_TRACKING_LOCATION_COMPLETED,
  FETCH_TRACKING_LOCATION_FAILED,
  FETCH_TRIP_DETAILS,
  FETCH_TRIP_DETAILS_COMPLETED,
  FETCH_TRIP_DETAILS_FAILED,
  EDIT_DRIVER_PHONE,
  EDIT_DRIVER_PHONE_COMPLETED,
  EDIT_DRIVER_PHONE_FAILED,
  ARRIVE_INTERMEDIATE,
  ARRIVE_INTERMEDIATE_COMPLETED,
  ARRIVE_INTERMEDIATE_FAILED,
  MARK_TOUCHING_OUT,
  MARK_TOUCHING_OUT_COMPLETED,
  MARK_TOUCHING_OUT_FAILED,
  DOWNLOAD_TRIP,
  DOWNLOAD_TRIP_COMPLETED,
  DOWNLOAD_TRIP_FAILED,
  EDIT_TRIP_VEHICLE,
  EDIT_TRIP_VEHICLE_FAILED,
  EDIT_TRIP_VEHICLE_COMPLETED,
  FETCH_DRIVER_CONSENT,
  FETCH_DRIVER_CONSENT_FAILED,
  FETCH_DRIVER_CONSENT_COMPLETED,
  RESEND_DRIVER_CONSENT,
  RESEND_DRIVER_CONSENT_FAILED,
  RESEND_DRIVER_CONSENT_COMPLETED,
  BEGIN_TRIP_WITHOUT_DRIVER_CONSENT,
  BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_FAILED,
  BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_COMPLETED,
} from '../trips';

import {
  FETCH_TRACKING_STATUS,
  FETCH_TRACKING_STATUS_COMPLETED,
  FETCH_TRACKING_STATUS_FAILED,
} from '../tracking';

import {
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_COMPLETED,
  FETCH_BOOKING_DETAILS_FAILED,
  REJECT_BOOKING,
  REJECT_BOOKING_COMPLETED,
  REJECT_BOOKING_FAILED,
  REVIVE_BOOKING,
  REVIVE_BOOKING_COMPLETED,
  REVIVE_BOOKING_FAILED,
  DEACTIVATE_BOOKING,
  DEACTIVATE_BOOKING_COMPLETED,
  DEACTIVATE_BOOKING_FAILED,
  ASSIGN_BOOKING,
  ASSIGN_BOOKING_COMPLETED,
  ASSIGN_BOOKING_FAILED,
  REASSIGN_BOOKING,
  REASSIGN_BOOKING_COMPLETED,
  REASSIGN_BOOKING_FAILED,
  DOCK_BOOKING,
  DOCK_BOOKING_COMPLETED,
  DOCK_BOOKING_FAILED,
} from '../bookings';
import {
  UPDATE_TRIP_SUPPORTING_DOC,
  UPDATE_TRIP_SUPPORTING_DOC_COMPLETED,
  UPDATE_TRIP_SUPPORTING_DOC_FAILED,
} from '../tripSupportingDocuments';
import {
  APPROVE_FASTTAG,
  APPROVE_FASTTAG_COMPLETED,
  APPROVE_FASTTAG_FAILED,
} from '../pages/approveFastTag';
import {
  APPROVE_DIESEL,
  APPROVE_DIESEL_COMPLETED,
  APPROVE_DIESEL_FAILED,
} from '../pages/approveDiesel';
import {
  FETCH_TRIP_CHARGES,
  FETCH_TRIP_CHARGES_COMPLETED,
  FETCH_TRIP_CHARGES_FAILED,
  UPDATE_REQUEST_STATUS,
  UPDATE_REQUEST_STATUS_COMPLETED,
  UPDATE_REQUEST_STATUS_FAILED,
} from '../pages/tripAdvance';
import {
  FETCH_TRIP_ADVANCE_HISTORY,
  FETCH_TRIP_ADVANCE_HISTORY_COMPLETED,
  FETCH_TRIP_ADVANCE_HISTORY_FAILED,
  FETCH_TRIP_CHARGES_BY_CODE,
  FETCH_TRIP_CHARGES_BY_CODE_COMPLETED,
  FETCH_TRIP_CHARGES_BY_CODE_FAILED,
  FETCH_PREV_TRIP_CHARGES,
  FETCH_PREV_TRIP_CHARGES_COMPLETED,
  FETCH_PREV_TRIP_CHARGES_FAILED,
} from '../pages/requestStatus';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_DHANUKA_MODAL = 'CLOSE_DHANUKA_MODAL';
export const CLEAR_ERROR = 'CLEAR_ERROR';

const initialState = {
  visible: false,
  isError: false,
  requesting: false,
  diesel_request: false,
  closeModalData: {},
};

//data is for extra data pass to the containers on modal open
export const open = (modalType, entityId, data, active_tab, diesel_request) => {
  //entity id - booking id, trip id etc

  return (dispatch) => {
    dispatch({
      type: OPEN_MODAL,
      payload: {
        modalType,
        entityId: entityId,
        data,
        active_tab,
        diesel_request,
      },
    });
  };
};

export const close = () => {
  return (dispatch) => {
    dispatch({type: CLOSE_MODAL});
  };
};

export const closeDhanukaModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_DHANUKA_MODAL,
      payload: data,
    });
  };
};

export const clearMsg = () => {
  return {type: CLEAR_ERROR};
};

export default (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case OPEN_MODAL:
      return {
        visible: true,
        modalType: payload.modalType,
        entityId: payload.entityId,
        //entity id - booking id, trip id etc
        data: payload.data,
        active_tab: payload.active_tab,
        diesel_request: payload.diesel_request,
        // fl: payload.fl,
      };

    case CLOSE_MODAL:
      return {
        visible: false,
      };
    case CLOSE_DHANUKA_MODAL:
      return {
        visible: false,
        closeModalData: {...payload},
      };
    case EDIT_TRIP_ROUTE:
    case MARK_TRIP_AS_BEGIN:
    case EDIT_DRIVER_PHONE:
    case ARRIVE_INTERMEDIATE:
    case ADDING_TRIP_COMMENT:
    case ADD_RUNNING_STATUS:
    case MARK_TOUCHING_OUT:
    case FETCH_BOOKING_DETAILS:
    case REJECT_BOOKING:
    case REVIVE_BOOKING:
    case DEACTIVATE_BOOKING:
    case ASSIGN_BOOKING:
    case REASSIGN_BOOKING:
    case DOCK_BOOKING:
    case UPDATE_TRIP_SUPPORTING_DOC:
    case EDIT_TRIP_VEHICLE:
    case BEGIN_TRIP_WITHOUT_DRIVER_CONSENT:
    case RESEND_DRIVER_CONSENT:
    case APPROVE_FASTTAG:
    case APPROVE_DIESEL:
    case FETCH_TRIP_CHARGES:
    case FETCH_TRIP_ADVANCE_HISTORY:
    case UPDATE_REQUEST_STATUS:
    case FETCH_TRIP_CHARGES_BY_CODE:
    case FETCH_PREV_TRIP_CHARGES:
      return {...state, requesting: true};

    case EDIT_TRIP_ROUTE_FAILED:
    case MARK_TRIP_AS_BEGIN_FAILED:
    case EDIT_DRIVER_PHONE_FAILED:
    case ADDING_TRIP_COMMENT_FAILED:
    case ADD_RUNNING_STATUS_FAILED:
    case ARRIVE_INTERMEDIATE_FAILED:
    case MARK_TOUCHING_OUT_FAILED:
    case FETCH_BOOKING_DETAILS_FAILED:
    case REJECT_BOOKING_FAILED:
    case REVIVE_BOOKING_FAILED:
    case DEACTIVATE_BOOKING_FAILED:
    case ASSIGN_BOOKING_FAILED:
    case REASSIGN_BOOKING_FAILED:
    case DOCK_BOOKING_FAILED:
    case UPDATE_TRIP_SUPPORTING_DOC_FAILED:
    case EDIT_TRIP_VEHICLE_FAILED:
    case BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_FAILED:
    case RESEND_DRIVER_CONSENT_FAILED:
    case APPROVE_FASTTAG_FAILED:
    case APPROVE_DIESEL_FAILED:
    case FETCH_TRIP_CHARGES_FAILED:
    case FETCH_TRIP_ADVANCE_HISTORY_FAILED:
    case UPDATE_REQUEST_STATUS_FAILED:
    case FETCH_TRIP_CHARGES_BY_CODE_FAILED:
    case FETCH_PREV_TRIP_CHARGES_FAILED:
      return {
        ...state,
        requesting: false,
        isError: true,
        error: payload.error,
      };

    case ADDING_TRIP_COMMENT_COMPLETED:
    case ADD_RUNNING_STATUS_COMPLETED:
    case EDIT_TRIP_ROUTE_COMPLETED:
    case MARK_TRIP_AS_BEGIN_COMPLETED:
    case EDIT_DRIVER_PHONE_COMPLETED:
    case ARRIVE_INTERMEDIATE_COMPLETED:
    case MARK_TOUCHING_OUT_COMPLETED:
    case FETCH_BOOKING_DETAILS_COMPLETED:
    case REVIVE_BOOKING_COMPLETED:
    case REJECT_BOOKING_COMPLETED:
    case DEACTIVATE_BOOKING_COMPLETED:
    case ASSIGN_BOOKING_COMPLETED:
    case REASSIGN_BOOKING_COMPLETED:
    case DOCK_BOOKING_COMPLETED:
    case UPDATE_TRIP_SUPPORTING_DOC_COMPLETED:
    case EDIT_TRIP_VEHICLE_COMPLETED:
    case BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_COMPLETED:
    case RESEND_DRIVER_CONSENT_COMPLETED:
    case APPROVE_FASTTAG_COMPLETED:
    case APPROVE_DIESEL_COMPLETED:
    case FETCH_TRIP_CHARGES_COMPLETED:
    case FETCH_TRIP_ADVANCE_HISTORY_COMPLETED:
    case UPDATE_REQUEST_STATUS_COMPLETED:
    case FETCH_TRIP_CHARGES_BY_CODE_COMPLETED:
    case FETCH_PREV_TRIP_CHARGES_COMPLETED:
      return {
        ...state,
        visible: true,
        requesting: false,
        error: '',
        isError: false,
      };
    case FETCH_TRACKING_LOCATION:
    case FETCH_TRIP_DETAILS:
    case TRIP_DETAILS_MESSAGE:
    case FETCH_TRACKING_STATUS:
    case DOWNLOAD_TRIP:
    case FETCH_DRIVER_CONSENT:
      return {
        ...state,
        loading: true,
        error: '',
        isError: false,
      };
    case FETCH_TRACKING_LOCATION_COMPLETED:
    case FETCH_TRIP_DETAILS_COMPLETED:
    case TRIP_DETAILS_MESSAGE_COMPLETED:
    case FETCH_TRACKING_STATUS_COMPLETED:
    case DOWNLOAD_TRIP_COMPLETED:
    case FETCH_DRIVER_CONSENT_COMPLETED:
      return {
        ...state,
        loading: false,
        error: '',
        isError: false,
      };
    case FETCH_TRACKING_LOCATION_FAILED:
    case FETCH_TRIP_DETAILS_FAILED:
    case TRIP_DETAILS_MESSAGE_FAILED:
    case FETCH_TRACKING_STATUS_FAILED:
    case DOWNLOAD_TRIP_FAILED:
    case FETCH_DRIVER_CONSENT_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        error: payload.error,
      };
    case CLEAR_ERROR:
      return {...state, error: {}};
    default:
      return state;
  }
};
